import React from "react"
import { PropTypes } from "prop-types"
import styled from "styled-components"
import tw from "tailwind.macro"
import classNames from "classnames"
import Link from "../Link"

import bgleaf from "../../images/leaf.svg"

const IconListSection = styled.div`
  ${tw`md:pl-20 lg:pl-40 xl:pl-0 xl:pt-48`}

  ul {
    ${tw`xl:flex xl:justify-between xl:flex-wrap`}
  }
`

const Icon = styled.div`
  ${tw`mr-15 pl-8 bg-no-repeat bg-contain sm:mr-12 xl:mr-0`}
  background-image: url(${bgleaf});

  @media (min-width: 768px) {
    background-size: 70px 40px;
  }

  @media (min-width: 1280px) {
    background-size: 70px 47px;
  }

  img {
    min-width: ${props => (props.smallIcons ? "54px" : "64px")};
    min-height: ${props => (props.smallIcons ? "45px" : "64px")};
    max-width: 64px;
    max-height: 64px;

    @media (min-width: 1280px) {
      min-width: ${props => (props.smallIcons ? "60px" : "64px")};
      min-height: ${props => (props.smallIcons ? "60px" : "64px")};
    }
  }
`

const Items = styled.li`
  ${tw`flex items-center text-base pb-15 md:pb-28 lg:pb-20 md:text-lg lg:text-xl xl:pr-16 xl:flex-col xl:text-center xl:w-1/2 xl:items-start`}

  p {
    ${tw`text-primary-dark font-medium text-xl leading-9`}
    letter-spacing: -0.4px;

    a {
      ${tw`text-xl`}

      :hover {
        ${tw`underline`}
      }
    }
  }
`

const IconListHorizontal = ({
  items,
  smallIcons,
  className,
  iconFieldName = "icon",
  textFieldName = "title",
  fieldLink = "#",
}) => {
  return (
    <IconListSection
      className={classNames("icon-list-horizontal horizontal", className)}
    >
      <ul
        className={
          smallIcons
            ? "-mb-8 md:-mb-28 lg:-mb-20"
            : "-mb-30 md:-mb-28 lg:-mb-30"
        }
      >
        {items?.map((item, index) => (
          <Items
            key={index}
          >
            <Icon smallIcons={smallIcons}>
              <img
                src={item[iconFieldName]?.localFile?.publicURL}
                alt={item.title}
              />
            </Icon>

            {item[fieldLink] ? (
              <p>
                <Link to={item[fieldLink]}>{item[textFieldName]}</Link>
              </p>
            ) : (
              <p>{item[textFieldName]}</p>
            )}
          </Items>
        ))}
      </ul>
    </IconListSection>
  )
}

IconListHorizontal.propTypes = {
  smallIcons: PropTypes.bool,
  className: PropTypes.string,
}

IconListHorizontal.defaultProps = {
  smallIcons: false,
}

export default IconListHorizontal

import React from "react"
import SEO from "components/seo"
import styled from "styled-components"
import tw from "tailwind.macro"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import HeroBanner from "../components/HeroBanner"
import CTA from "../components/CTA"
import IconList from "../components/IconList"
import IconListHorizontal from "../components/IconListHorizontal"
import TwoColContent from "../components/TwoColContent"
import PetalBanner from "../components/PetalBanner"
import Testimonials from "../components/Testimonials"

export const query = graphql`
  query Home {
    page: allWpPage(filter: { slug: { eq: "home" } }) {
      edges {
        node {
          acfHome {
            homeBannerContent
            homeBannerTitle
            homeBannerTitleTagline
            homeBannerLink1 {
              target
              title
              url
            }
            homeBannerLink2 {
              target
              title
              url
            }
            homeBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homeDistributorsContent
            homeDistributorsTitleTagline
            homeDistributorsTitle
            homeDistributorsLink {
              target
              title
              url
            }
            homeDistributorsIcons {
              homeDistributorsColumnLink
              homeDistributorsText
              homeDistributorsIcon {
                localFile {
                  publicURL
                }
              }
            }
            homePetalImageContent
            homePetalImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            homeSellersContent
            homeSellersTitle
            homeSellersTitleTagline
            homeSellersLink {
              target
              title
              url
            }
            homeSellersIcons {
              homeSellersColumnLink
              homeSellersText
              homeSellersIcon {
                localFile {
                  publicURL
                }
              }
            }
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const FirstContentRow = styled.section`
  ${tw`max-w-xl mx-auto px-18 md:px-40 lg:px-60 xxl:px-0 xl:mb-30`}

  .first-content-row-inner {
    ${tw`md:flex md:items-center xl:flex-col`}

    @media (min-width: 1280px) {
      width: 46%;
    }
  }

  .two-col-content {
    ${tw`md:w-1/2 xl:w-full`}
  }

  .icon-list-horizontal {
    ${tw`pt-50 md:pt-0 xl:pt-50 md:w-1/2 xl:w-full`}
  }
`

const SecondContentRow = styled.section`
  ${tw`max-w-xl mx-auto px-18 mt-40 flex flex-col-reverse sm:mt-10 md:flex-row-reverse md:items-center md:mt-42 md:px-40 lg:px-60 xxl:px-0 xl:mt-0 xl:items-start xl:flex-row`}

  .two-col-content {
    ${tw`md:w-1/2 xl:w-full`}

    @media (min-width: 1280px) {
      margin-top: 235px;
    }
  }

  .icon-list {
    ${tw`z-50 mt-50 ml-0 md:mt-0 md:pl-20 md:w-1/2 xl:w-full xl:pl-0`}
  }
`

const IndexPage = ({ location, data }) => {
  const { acfHome, seo, acfCta, acfTestimonials } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />
      <HeroBanner
        subTitle={acfHome.homeBannerTitleTagline}
        mainTitle={acfHome.homeBannerTitle}
        description={acfHome.homeBannerContent}
        buttonOne={acfHome.homeBannerLink1}
        buttonTwo={acfHome.homeBannerLink2}
        image={acfHome.homeBannerImage?.localFile.childImageSharp.fluid}
      />
      <FirstContentRow className="section-gap-sm md:section-gap-md">
        <div class="first-content-row-inner">
          <TwoColContent
            className="content-col-xl pr-none-important"
            titlesHideForTab={false}
            centerTitlesTab={false}
            fullWidthTab={true}
            fullWidthLarge={false}
            colTitleMaxWidth={false}
            subTitle={acfHome.homeDistributorsTitleTagline}
            mainTitle={acfHome.homeDistributorsTitle}
            textContent={acfHome.homeDistributorsContent}
            button={acfHome.homeDistributorsLink}
          />

          <IconListHorizontal
            smallIcons={true}
            items={acfHome.homeDistributorsIcons}
            iconFieldName="homeDistributorsIcon"
            textFieldName="homeDistributorsText"
            fieldLink="homeDistributorsColumnLink"
          />
        </div>
      </FirstContentRow>

      <PetalBanner
        petalText={acfHome.homePetalImageContent}
        petalImage={acfHome.homePetalImage.localFile.childImageSharp.fluid}
        className="xl:pt-none-important"
      />

      <SecondContentRow>
        <IconList
          horizontal={false}
          smallIcons={true}
          items={acfHome.homeSellersIcons}
          iconFieldName="homeSellersIcon"
          textFieldName="homeSellersText"
          fieldLink="homeSellersColumnLink"
        />
        <TwoColContent
          className="pr-none-important"
          titlesHideForTab={false}
          centerTitlesTab={false}
          fullWidthTab={true}
          fullWidthLarge={false}
          colTitleMaxWidth={false}
          subTitle={acfHome.homeSellersTitleTagline}
          mainTitle={acfHome.homeSellersTitle}
          textContent={acfHome.homeSellersContent}
          button={acfHome.homeSellersLink}
        />
      </SecondContentRow>

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default IndexPage

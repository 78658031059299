import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../Link"

import BackgroundImage from "gatsby-background-image"
import bannerImage from "../../images/banner-image.png"

const HeroBannerSection = styled.section`
  ${tw`justify-around bg-grey pt-34 md:pt-0 md:mb-0`}
  margin-bottom: 300px;

  @media (min-width: 768px) {
    padding-top: 105px;
  }

  @media (min-width: 1280px) {
    padding-top: 175px;
  }
`

const HeroBannerContainer = styled.div`
  ${tw`flex flex-wrap px-18 md:px-40 lg:px-60 xxl:px-0 mx-auto max-w-xl`}
  min-height: 680px;

  @media (min-width: 768px) {
    height: calc(100vh - 105px);
  }

  @media (min-width: 1280px) {
    height: calc(100vh - 175px);
  }
`

const HeroBannerLeft = styled.div`
  ${tw`flex justify-between w-full flex-col md:self-center md:w-3/5 xl:w-2/4 xl:pr-70`}
`

const HeroBannerRight = styled.div`
  ${tw`mx-auto mt-32 relative md:h-auto md:w-2/5 md:my-0 md:-mb-24 md:-mr-62 lg:-mb-58 xl:mr-0 xl:mb-0 xl:w-2/4`}
  width: 310px;
  height: 606px;
  margin-bottom: -300px;
`

const HeroContent = styled.div`
  h5 {
    ${tw`font-fb text-secondary-success uppercase leading-7 lg:leading-9 lg:whitespace-no-wrap mb-5 -mt-4`}
  }

  h1 {
    ${tw`text-primary mb-10`}
  }

  .hero-banner-description {
    p {
      ${tw`font-medium md:text-2xl md:leading-9`}

      :last-child {
        ${tw`mb-0`}
      }
    }
  }

  .btn-wrap {
    ${tw`pt-14 md:flex md:pt-20`}
  }
`

const HeroImage = styled(BackgroundImage)`
  ${tw`w-full bg-no-repeat bg-center bg-cover h-full`}

  &:after,
  &:before {
    border-radius: 0 200px;
  }

  @media (min-width: 768px) {
    height: 870px;
    position: absolute !important;
    top: 0;

    :after,
    :before {
      border-radius: 0 215px;
    }
  }

  @media (min-width: 1024px) {
    height: 805px;

    :after,
    :before {
      border-radius: 0 210px;
    }
  }

  @media (min-width: 1280px) {
    height: 1584px;
    width: calc(100% + 100px);

    :after,
    :before {
      border-radius: 0 430px;
    }
  }
`

const HeroBanner = ({
  subTitle,
  mainTitle,
  description,
  buttonOne,
  buttonTwo,
  image,
}) => {
  return (
    <HeroBannerSection>
      <HeroBannerContainer>
        <HeroBannerLeft>
          <HeroContent>
            {subTitle && <h5>{subTitle}</h5>}
            <h1 className="h1-xs md:h1-md">{mainTitle}</h1>
            <div
              className="hero-banner-description"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
            <div className="btn-wrap">
              <Link
                className="btn-secondary btn-secondary-min-w"
                href={buttonOne.url}
                target={buttonOne.target}
              >
                {buttonOne.title}
              </Link>
              <Link
                className="btn-outline mt-12 md:mt-0 md:ml-10"
                href={buttonTwo.url}
                target={buttonTwo.target}
              >
                {buttonTwo.title}
              </Link>
            </div>
          </HeroContent>
        </HeroBannerLeft>
        <HeroBannerRight>
          <HeroImage
            tag="div"
            className="hero-image"
            fluid={image ? image : bannerImage}
          />
        </HeroBannerRight>
      </HeroBannerContainer>
    </HeroBannerSection>
  )
}

export default HeroBanner
